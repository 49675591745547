<template>
  <div class="flex flex-col h-full p-6">
    <div class="flex justify-end pb-6 flex-shrink-0">
      <el-button
        type="primary"
        class="flex items-center h-th-action-button-height"
        @click="buttons[0].clickHandler"
      >
        <svgicon
          :src="require('@/assets/icons/th-icon-plus.svg')"
          :style="{ height: '20px', width: '20px' }"
          class="mr-1 fill-current"
        />
        {{ buttons[0].label }}
      </el-button>
    </div>

    <base-card class="flex-grow p-12 flex">
      <div
        class="h-full flex flex-col leading-relaxed leading text-md max-w-lg"
        :class="{ italic: !isWhiteLabel }"
      >
        <div>
          <div class="font-bold text-lg mb-2">
            {{ $t('pages.voucher_systems.empty.text.title') }}
          </div>
          <div>{{ $t('pages.voucher_systems.empty.text.sub') }}</div>
        </div>
        <div class="pt-4">
          <div>
            {{ $t('pages.voucher_systems.empty.text.steps.one.title') }}
          </div>
          <div class="pl-4">
            <div>
              {{ $t('pages.voucher_systems.empty.text.steps.one.sub.one') }}
            </div>
            <div>
              {{ $t('pages.voucher_systems.empty.text.steps.one.sub.two') }}
            </div>
            <div>
              {{ $t('pages.voucher_systems.empty.text.steps.one.sub.three') }}
            </div>
          </div>
          <div>
            {{ $t('pages.voucher_systems.empty.text.steps.two') }}
          </div>
          <div>
            {{ $t('pages.voucher_systems.empty.text.steps.three.title') }}
          </div>
          <div class="pl-4">
            <div>
              {{ $t('pages.voucher_systems.empty.text.steps.three.sub.one') }}
            </div>
            <div>
              {{ $t('pages.voucher_systems.empty.text.steps.three.sub.two') }}
            </div>
            <div>
              {{ $t('pages.voucher_systems.empty.text.steps.three.sub.three') }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="hidden lg:flex w-full h-full justify-end align-bottom items-end xl:ml-8"
      >
        <img
          :src="voucherSystemsListEmpty"
          alt="voucher-systems-list-empty"
          style="max-height: 95%"
        />
      </div>
    </base-card>
  </div>
</template>

<script>
import voucherSystemsListEmpty from '@/assets/illustrations/voucher-systems-list-empty.svg'
import BaseCard from '@/components/base-card'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    BaseCard
  },
  data() {
    return {
      voucherSystemsListEmpty,
      buttons: [
        {
          scopes: ['loyalty.voucher_systems:create'],
          svgicon: 'th-icon-plus',
          label: this.$t('common.components.th_datatable.addNew'),
          clickHandler: this.handleNew
        }
      ]
    }
  },
  computed: {
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  methods: {
    handleNew() {
      this.$router.push({ name: 'voucher-systems-new' })
    }
  }
}
</script>
